import { Injectable } from '@angular/core';

import { filter, map, tap } from 'rxjs/operators';

import { compareAsc, compareDesc, format } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';

import { MappingApiType, Request } from '@camelot/server';
import { getUniqueValues, isNonNullable, toLocalDateString } from '@camelot/utils';

import { BaseService } from 'src/app/core/server/baseService';

import { Team } from '../common/dto/team';
import { Picture } from '../files/dto/picture';
import { ModifierPayload } from './dto/modifier-payload';
import { Status } from './dto/status';
import { Type } from './dto/type';
import { VisitDetail } from './dto/visit-detail';
import { VisitInProgress } from './dto/visit-in-progress';
import { VisitLight } from './dto/visit-light';

const apiRoutes: MappingApiType = {
  ChangeVisitStatus: {
    type: 'PUT',
    url: '{ApiUrl}/visits/{type}/{id}?newStatus={status}',
  },
  GetVisitsByMonth: {
    type: 'GET',
    url: '{ApiUrl}/visits/ByMonth?date={date}',
  },
  GetVisitsByDay: {
    type: 'GET',
    url: '{ApiUrl}/visits/ByDay?date={date}',
  },
  GetVisitDetail: {
    type: 'GET',
    url: '{ApiUrl}/visits/{type}/{id}',
  },
  GetPics: {
    type: 'GET',
    url: '{ApiUrl}/visits/{type}/{id}/pictures',
  },
  UpdateVisit: {
    type: 'PUT',
    url: '{ApiUrl}/visits/modify/{type}/{id}',
  },
  CancelVisit: {
    type: 'DELETE',
    url: '{ApiUrl}/visits/cancel/{type}/{id}',
  },
  UploadFiles: {
    type: 'FILES',
    url: '{ApiUrl}/visits/{type}/{id}/pictures',
  },
  UpdateFiles: {
    type: 'UPDATEFILES',
    url: '{ApiUrl}/visits/{type}/{id}/pictures/{picId}',
  },
  DeleteFile: {
    type: 'DELETE',
    url: '{ApiUrl}/visits/{type}/{id}/pictures/{fileId}',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AppVisitsService extends BaseService {
  private _byMonth$ = new BehaviorSubject<{ [index: string]: VisitLight[] }>({});
  private _byDay$ = new BehaviorSubject<{ [index: string]: VisitDetail[] }>({});

  private _byDetail$ = new BehaviorSubject<{ [index: string]: VisitDetail }>({});

  private _getPics$ = new BehaviorSubject<{
    [index: string]: Picture[];
  }>({});

  constructor() {
    super(apiRoutes);
  }

  public changeVisitStatus$(type: Type, id: number, status: Status) {
    return this._serverService.request<VisitInProgress>(
      new Request({ type: 'ChangeVisitStatus', content: { type, id, status } })
    );
  }

  public updateVisit$(type: Type, id: number, modifierPayload: ModifierPayload) {
    return this._serverService.request<VisitDetail>(
      new Request({ type: 'UpdateVisit', urlData: { type: type, id: id }, content: modifierPayload })
    );
  }

  public cancelVisit$(type: Type, id: number) {
    return this._serverService.request<VisitDetail>(new Request({ type: 'CancelVisit', content: { type, id } }));
  }

  /*
   * Get By month
   */
  public getByMonth$ = (date: Date): Observable<VisitLight[]> =>
    this._byMonth$.pipe(
      map(data => data[this._getKeyByDate(date)]),
      filter(myData => !!myData),
      map(visits =>
        visits.map(visit => ({
          ...visit,
          ...{
            startDate: toLocalDateString(visit.startDate),
            endDate: toLocalDateString(visit.endDate),
          },
        }))
      )
    );

  public fetchVisitsByMonth$(date: Date) {
    const key = this._getKeyByDate(date);
    return this._serverService
      .request<VisitLight[]>(new Request({ type: 'GetVisitsByMonth', content: { date: key } }))
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._byMonth$.getValue();
          entities[key] = entity;
          this._byMonth$.next(entities);
        })
      );
  }

  /*
   * Get By Day
   */
  public getByDay$ = (date: Date): Observable<VisitDetail[]> =>
    this._byDay$.pipe(
      map(data => data[this._getKeyByDate(date)]),
      filter(myData => !!myData),
      map(visits =>
        visits.map(visit => ({
          ...visit,
          ...{
            startDate: toLocalDateString(visit.startDate),
            endDate: toLocalDateString(visit.endDate),
          },
        }))
      ),
      map(visits => visits.sort((a, b) => compareAsc(new Date(a.startDate), new Date(b.startDate))))
    );

  public getByDayFilteredByTeam$ = (date: Date, filterTeams: string[] | null) => {
    return this.getByDay$(date).pipe(
      map(visits =>
        visits.filter(visit => {
          if (!filterTeams || filterTeams.find(filter => filter === 'All')) return true;
          if (!visit.team && filterTeams.find(filter => filter === 'Mine')) return true;
          if (!visit.team) return false;

          return !!filterTeams.find(team => visit.team?.name === team);
        })
      )
    );
  };

  public getTeams$ = (date: Date): Observable<Team[]> =>
    this.getByDay$(date).pipe(
      map(visits => visits.map(visit => visit.team).filter(team => !!team)),
      map(teams => getUniqueValues(teams.filter(isNonNullable), (x: Team) => x.id))
    );

  public fetchVisitsByDay$(date: Date) {
    const key = this._getKeyByDate(date);
    return this._serverService
      .request<VisitDetail[]>(new Request({ type: 'GetVisitsByDay', content: { date: key } }))
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._byDay$.getValue();
          entities[key] = entity;
          this._byDay$.next(entities);
        })
      );
  }

  /*
   * Get Detail
   */
  public getDetail$ = (id: number): Observable<VisitDetail> =>
    this._byDetail$.pipe(
      map(data => data[id]),
      filter(myData => !!myData),
      map(visit => ({
        ...visit,
        ...{
          startDate: visit.startDate ? toLocalDateString(visit.startDate) : '',
          endDate: visit.endDate ? toLocalDateString(visit.endDate) : '',
        },
      }))
    );

  public fetchVisitDetail$(data: { type: Type; id: number }) {
    return this._serverService
      .request<VisitDetail>(new Request({ type: 'GetVisitDetail', content: data, cacheTime: 0 }))
      .pipe(
        filter(data => !!data),
        tap(entity => {
          const entities = this._byDetail$.getValue();
          entities[data.id] = entity;
          this._byDetail$.next(entities);
        })
      );
  }

  /*
   * Get Pics
   */
  public getPic = (id: number) => (picId: number) => {
    return this._getPics$.getValue()[id].find(pic => pic.id === picId);
  };
  public getPics$ = (id: number): Observable<Picture[]> =>
    this._getPics$.pipe(
      map(data => data[id]),
      filter(myData => !!myData),
      map(pics => pics.sort((a, b) => compareDesc(new Date(a.createdDate || ''), new Date(b.createdDate || ''))))
    );

  public fetchPics$(data: { type: Type; id: number }) {
    return this._serverService.request<Picture[]>(new Request({ type: 'GetPics', content: data, cacheTime: 0 })).pipe(
      filter(data => !!data),
      tap(entity => {
        const entities = this._getPics$.getValue();
        entities[data.id] = entity;
        this._getPics$.next(entities);
      })
    );
  }

  /*
   * Files
   */
  public uploadFiles$(data: { files: File[]; type: Type; id: number }) {
    const formData = new FormData();
    for (const [index, file] of data.files.entries()) {
      formData.append('file' + index, file, file.name);
    }
    return this._serverService.request<Picture[]>(
      new Request({
        type: 'UploadFiles',
        content: {
          files: formData,
          type: data.type,
          id: data.id,
        },
      })
    );
  }

  public updateFiles$(data: { files: File[]; type: Type; id: number; picId: number }) {
    const formData = new FormData();
    for (const [index, file] of data.files.entries()) {
      formData.append('file' + index, file, file.name);
    }
    return this._serverService.request<Picture[]>(
      new Request({
        type: 'UpdateFiles',
        content: {
          files: formData,
          type: data.type,
          id: data.id,
          picId: data.picId,
        },
      })
    );
  }

  public deleteFile$(data: { type: Type; id: number; fileId: number }) {
    return this._serverService.request<Picture[]>(new Request({ type: 'DeleteFile', content: data }));
  }

  private _getKeyByDate(date: Date): string {
    return format(date, 'yyyy-MM-dd');
  }
}
