import { BadgeType } from '@camelot/ui';

import { Status } from 'src/app/services/visits/dto/status';
import { Type } from 'src/app/services/visits/dto/type';

export const getCardClass = (visit: { status: Status }) => {
  switch (visit.status) {
    case Status.InProgress:
      return 'card-in-progress';
    default:
      return '';
  }
};

/**
 * Status
 */
export const getVisitStatusType = (visit: { status: Status }): BadgeType => {
  switch (visit.status) {
    case Status.Done:
      return 'success';
    case Status.InProgress:
      return 'primary';
    case Status.Planned:
      return 'info';
    case Status.ToBePlanned:
      return 'warning';
    case Status.Cancelled:
      return 'danger';
    default:
      return 'info';
  }
};

export const getVisitTypeType = (visit: { type: Type }): BadgeType => {
  switch (visit.type) {
    case Type.Advisor:
      return 'success';
    case Type.Technical:
      return 'warning';
    case Type.Sav:
      return 'danger';
    case Type.WorkingSite:
      return 'info';
    case Type.Recepetion:
      return 'warning';
    case Type.Maintenance:
      return 'primary';
    default:
      return 'secondary';
  }
};

export const getVisitStatusIcon = (visit: { status: Status }) => {
  switch (visit.status) {
    case Status.Done:
      return 'task_alt';
    case Status.InProgress:
      return 'play_circle_outline';
    default:
      return 'radio_button_unchecked';
  }
};

export const getVisitStatusIconClass = (visit: { status: Status }) => {
  if (visit.status === Status.Done) return 'status-done';
  return '';
};

export const getVisitStatusTranslation = (visit: { status: Status }) => {
  return `visits.status.label.${Status[visit.status].toLocaleLowerCase()}`;
};

/**
 * Type
 */
export const getVisitTypeTranslation = (visit: { type: Type }) => {
  return `visits.type.label.${Type[visit.type].toLocaleLowerCase()}`;
};
